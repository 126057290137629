<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Financeiro</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Realizar Saque"
              @click.prevent="$router.push({ name: 'financeiro' })"
            >
              <i class="bx bx-wallet font-size-16 align-middle mr-2"></i>
              Transações
            </b-button>
            <b-button
              variant="info"
              class="ml-2"
              @click.prevent="adicionar()"
            >
              <i class="bx bx-wallet font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="contasData.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div
        v-for="conta in contasData"
        :key="conta.id"
        class="col-lg-4 col-md-6"
      >
        <div class="card text-center">
          <div class="card-body">
            <h5 class="font-size-15 text-dark">
              {{ conta.name }}
            </h5>
            <p class="text-muted text-left mt-3">
              <strong>Nome:</strong>
              {{ conta.name_owner }}
              <br />
              <strong>Data de Nascinento:</strong>
              {{ conta.date_owner | moment('DD/MM/YYYY') }}
              <br />
              <strong>Banco:</strong>
              {{ conta.bank_code }} - {{ conta.bank }}
              <br />
              <strong>Tipo da conta:</strong>
              {{ conta.type }}
              <br />
              <strong>Agência:</strong>
              {{ conta.agency }}
              <br />
              <strong>Número da Conta:</strong>
              {{ conta.number }}
              <br />
              <strong>Dígito:</strong>
              {{ conta.digit }}
              <br />
              <strong>CPF:</strong>
              {{ conta.cpf }}
              <br />
              <strong>Cadastro:</strong>
              {{ conta.created_at | moment('DD/MM/YYYY') }}
            </p>
          </div>
          <div
            class="card-footer bg-transparent border-top text-center font-size-20"
          >
            <button
              class="btn btn-success"
              v-b-tooltip.hover.top
              title="Editar"
              @click.prevent="editar(conta)"
            >
              <i class="fas fa-pencil-alt text-white"></i>
            </button>
            <button
              class="btn btn-danger ml-2"
              v-b-tooltip.hover.top
              title="Remover"
              @click.prevent="removerItem(conta)"
            >
              <i class="fas fa-trash-alt text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <b-modal v-model="showModal" title="Conta Bancária" centered>
      <div class="row">
        <div class="col-12">
          <label>
            <span class="mr-1 text-danger">*</span>
            Nome
          </label>
          <b-form-group>
            <b-form-input
              v-model="form.name"
              :class="{ 'is-invalid': form && $v.form.name.$error }"
              placeholder="Nome da conta"
              for="text"
            ></b-form-input>
            <small>Nome simbólico.</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <label>
            <span class="mr-1 text-danger">*</span>
            Nome do Proprietário
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Nome do proprietário"
              v-model="form.name_owner"
              :class="{ 'is-invalid': form && $v.form.name_owner.$error }"
              for="text"
            ></b-form-input>
            <small>Nome do proprietário da conta bancária.</small>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <label>
            <span class="mr-1 text-danger">*</span>
            Data de Nascimento
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Data de nascimento"
              v-model="form.date_owner"
              :class="{ 'is-invalid': form && $v.form.date_owner.$error }"
              type="date"
            ></b-form-input>
            <small>Data de nascimento do proprietário da conta.</small>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <label>
            <span class="mr-1 text-danger">*</span>
            CPF/CNPJ
          </label>
          <b-form-group>
            <b-form-input
              placeholder="CPF/CNPJ"
              v-model="form.cpf"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              :class="{ 'is-invalid': form && $v.form.cpf.$error }"
              for="text"
            ></b-form-input>
            <small>CPF ou CNPJ do proprietário da conta bancária.</small>
          </b-form-group>
        </div>
        <div class="col-12 mt-1">
          <label>
            <span class="mr-1 text-danger">*</span>
            Banco
          </label>
          <multiselect
            :options="options_bancos"
            class="helo"
            label="label"
            :custom-label="customLabel"
            placeholder="Banco"
            select-label="Selecionar"
            deselect-label="Remover"
            v-model="bank"
            :class="{ 'is-invalid': $v.bank.$error }"
          ></multiselect>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
          <label>
            <span class="mr-1 text-danger">*</span>
            Tipo
          </label>
          <multiselect
            :options="options_conta_tipo"
            class="helo"
            placeholder="Tipo da Conta Bancária"
            select-label="Selecionar"
            deselect-label="Remover"
            v-model="form.type"
            :class="{ 'is-invalid': form && $v.form.type.$error }"
          ></multiselect>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
          <label>
            <span class="mr-1 text-danger">*</span>
            Agência
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Número da Agência"
              for="text"
              v-model="form.agency"
              :class="{ 'is-invalid': form && $v.form.agency.$error }"
            ></b-form-input>
            <small>Número da agência sem dígito.</small>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 mt-1">
          <label>
            <span class="mr-1 text-danger">*</span>
            Conta
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Número da conta"
              for="text"
              v-model="form.number"
              :class="{ 'is-invalid': form && $v.form.number.$error }"
            ></b-form-input>
            <small>Número da conta bancária sem dígito.</small>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 mt-1">
          <label>
            <span class="mr-1 text-danger">*</span>
            Dígito
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Dígito"
              for="text"
              v-model="form.digit"
              :class="{ 'is-invalid': form && $v.form.digit.$error }"
            ></b-form-input>
            <small>Dígito da conta bancária.</small>
          </b-form-group>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click="cancelar()">
          Cancelar
        </b-button>
        <b-button variant="success" @click.prevent="salvarDados()">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.is-invalid .multiselect__tags {
  border-color: #f46a6a !important;
  border: 1px solid #f46a6a !important;
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import bancos from '@/assets/bancos.json'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  data() {
    return {
      showModal: false,
      options_conta_tipo: ['Corrente', 'Poupança'],
      contasData: [],
      filtro: {
        limit: 20,
        page: 1,
      },
      loading: true,
      erorr: false,
      loadingbtn: true,
      options_bancos: bancos,
      edit: false,
      bank: null,
      form: {
        name: null,
        name_owner: null,
        bank: null,
        number: null,
        digit: null,
        type: null,
        cpf: null,
        date_owner: null,
        agency: null,
      },
    }
  },
  validations: {
    bank: { required },
    form: {
      name: { required },
      name_owner: { required },
      number: { required },
      digit: { required },
      type: { required },
      cpf: { required },
      date_owner: { required },
      agency: { required },
    },
  },
  watch: {
    bank: function () {
      if (this.banck != null) {
        this.form.bank = this.bank.label
        this.form.bank_code = this.bank.value
      }
    },
  },
  methods: {
    ...mapActions('finance', [
      'ActionAccountAll',
      'ActionAccountUpdate',
      'ActionAccountCreate',
    ]),
    editar(data) {
      this.showModal = true
      this.edit = true
      this.form = data
      this.bank = { value: this.form.bank_code, label: this.form.bank }
    },
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitAccount()
      }
    },
    async submitAccount() {
      try {
        this.form.date_owner = moment(this.form.date_owner).format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )
        this.form.bank = this.bank.label
        this.form.bank_code = this.bank.value
        if (this.edit) {
          var dados = {
            data: this.form,
            id: this.form.id,
          }
          await this.ActionAccountUpdate(dados)
        } else {
          await this.ActionAccountCreate(this.form).then((res) => {
            this.contasData.push(res.data.data)
          })
        }
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          ;(this.bank = null),
            (this.form = {
              name: null,
              name_owner: null,
              bank: null,
              number: null,
              digit: null,
              type: null,
              cpf: null,
              date_owner: null,
              agency: null,
            })
          this.showModal = false
          this.edit = false
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    cancelar() {
      this.showModal = false
      this.edit = false
      ;(this.bank = null),
        (this.form = {
          name: null,
          name_owner: null,
          bank: null,
          number: null,
          digit: null,
          type: null,
          cpf: null,
          date_owner: null,
          agency: null,
        })
    },
    adicionar() {
      this.showModal = true
      this.edit = false
      ;(this.bank = null),
        (this.form = {
          name: null,
          name_owner: null,
          bank: null,
          number: null,
          digit: null,
          type: null,
          cpf: null,
          date_owner: null,
          agency: null,
        })
    },
    infiniteHandler($state) {
      this.loadingbtn = true
      this.ActionAccountAll(this.filtro).then(({ data }) => {
        if (data.data.list != null && data.data.list.length) {
          this.filtro.page += 1
          this.contasData.push(...data.data.list)
          $state.loaded()
        } else {
          this.loadingbtn = false
          $state.complete()
        }
        this.loading = false
        this.error = false
      })
    },
    customLabel({ label, value }) {
      return `${value} - ${label}`
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionAccountUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.contasData.splice(this.contasData.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
